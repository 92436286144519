<template>
    <div style="display: inline-flex">
        <el-select v-model="query.status" placeholder="状态" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="(v, k) in image_status_enum" :label="v" :value="parseInt(k)"></el-option>
            <el-option label="已上线" :value="10"></el-option>
        </el-select>
        <el-select v-model="query.category_id" placeholder="分类" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="c in category_list" :label="c.i18n_name.ZH_HANS" :value="c.id"></el-option>
        </el-select>
        <el-select v-model="query.module" placeholder="模块" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="(v, k) in image_module_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select v-model="query.type" placeholder="类型" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="(v, k) in image_type_enum" :label="v" :value="parseInt(k)"></el-option>
        </el-select>
        <el-select v-model="query.magic" placeholder="问号图" clearable style="width: 100px" :disabled="loading">
            <el-option label="是" :value="true"></el-option>
            <el-option label="否" :value="false"></el-option>
        </el-select>
        <el-select v-model="query.author" placeholder="作者" clearable style="width: 100px" :disabled="loading">
            <el-option v-for="a in author_list" :value="a"></el-option>
        </el-select>
        <el-input v-model="query.id" style="width: 100px" placeholder="图片ID" :disabled="loading"></el-input>
        <date-range-picker v-model="query" :disabled="loading" start-placeholder="上传开始时间"
                           end-placeholder="上传结束时间"></date-range-picker>
        <el-button type="primary" :loading="loading" @click="openDialog()">创建</el-button>
    </div>
    <el-table style="margin-top: 20px;" v-loading="loading" :data="dataPage">
        <el-table-column label="预览图" width="230">
            <template #default="scope">
                <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"
                          style="width: 100px"></el-image>
                <el-image lazy :src="`${$root.env.CDN_URL}/${scope.row.origin_colored}`"
                          style="width: 100px"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="ID" prop="id" width="150"></el-table-column>
        <el-table-column label="假ID" prop="nickname" width="100"></el-table-column>
        <el-table-column label="状态" prop="status" width="120">
            <template #default="scope">
                <span style="color: var(--el-color-success)"
                      v-if="scope.row.status === 1 && scope.row.online_date <= timestampToDate(Date.now())">
                    已上线</span>
                <el-select v-model="scope.row.status" :disabled="loading || scope.row.status === null"
                           @change="update(scope.row)" v-else>
                    <el-option :value="0" label="测试中"></el-option>
                    <el-option :value="1" label="通过"></el-option>
                    <el-option :value="2" label="不通过"></el-option>
                </el-select>
            </template>
        </el-table-column>
        <el-table-column label="像素块" prop="cells" width="80"></el-table-column>
        <el-table-column label="模块" prop="module" width="80">
            <template #default="scope">
                {{ image_module_enum[scope.row.module] }}
            </template>
        </el-table-column>
        <el-table-column label="类型" prop="type" width="60">
            <template #default="scope">
                {{ image_type_enum[scope.row.type] }}
            </template>
        </el-table-column>
        <el-table-column label="分类" prop="categories" width="100">
            <template #default="scope">
                <template v-for="c in scope.row.categories">
                    <template v-for="cc in category_list">
                        <el-tag size="small" v-if="cc.id===c">{{ cc.i18n_name.EN }}</el-tag>
                    </template>
                </template>
            </template>
        </el-table-column>
        <el-table-column label="上传时间" prop="c_time" width="120">
            <template #default="scope">
                {{ timestampToDate(scope.row.c_time) }}
            </template>
        </el-table-column>
        <el-table-column label="上线时间" prop="online_date" width="120"></el-table-column>
        <el-table-column label="操作" width="200">
            <template #default="scope">
                <el-button size="small" :disabled="loading" @click="openDialog(scope.row)">编辑</el-button>
                <el-button size="small" type="success" :disabled="loading" @click="$refs.region.init(scope.row)">
                    Region
                </el-button>
            </template>
        </el-table-column>
    </el-table>
    <div style="margin-top: 10px;text-align: center;">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
    <el-dialog v-model="dialog_opened" :before-close="reset" width="80%" :close-on-click-modal="false">
        <el-form ref="form" :model="form" label-width="100px">
            <el-form-item label="分类" prop="categories" :rules="[{required: true, message: '请选择分类'}]">
                <el-select v-model="form.categories" multiple style="width: 100%" filterable :disabled="loading">
                    <template v-for="c in category_list">
                        <el-option :value="c.id" :label="c.i18n_name.EN"
                                   v-if="!['HOT', 'REC', 'NEW'].includes(c.key)"></el-option>
                    </template>
                </el-select>
            </el-form-item>
            <el-form-item label="类型" prop="type" :rules="[{required: true, message: '请选择分类'}]">
                <el-radio-group v-model="form.type" :disabled="loading">
                    <el-radio v-for="(v, k) in image_type_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="模块" prop="module" :rules="[{required: true, message: '请选择模块'}]">
                <el-radio-group v-model="form.module" :disabled="loading">
                    <el-radio v-for="(v, k) in image_module_enum" :label="parseInt(k)">{{ v }}</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="激励视频" prop="video" :rules="[{required: true, message: '请选择是否使用激励视频解锁'}]">
                <el-radio-group v-model="form.video" :disabled="loading">
                    <el-radio :label="true">是</el-radio>
                    <el-radio :label="false">否</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="问号图" prop="magic" :rules="[{required: true, message: '请选择是否是问号图'}]">
                <el-radio-group v-model="form.magic" :disabled="loading">
                    <el-radio :label="false">否</el-radio>
                    <el-radio :label="true">是</el-radio>
                </el-radio-group>
            </el-form-item>
            <el-form-item label="缩略图" prop="origin_thumb" :rules="[{required: true, message: '请上传缩略图'}]">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleThumb"
                           accept="image/*" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                           :disabled="editing && [1].includes(editing.status)" :before-upload="handleBeforeUpload"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.origin_thumb" :src="`${$root.env.CDN_URL}/${form.origin_thumb}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="Colored" prop="origin_colored">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleColored"
                           accept="image/png" :data="{prefix: 'origin'}" :onProgress="handleProgress"
                           :disabled="editing && [1].includes(editing.status)" :before-upload="handleBeforeUpload"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.origin_colored" :src="`${$root.env.CDN_URL}/${form.origin_colored}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
            <el-form-item label="Extra" prop="origin_extra" :rules="[{required: true, message: '请上传Extra'}]"
                          v-if="![1, 2, 7, 8].includes(form.type)">
                <el-upload action="/cms/v1/resource" :show-file-list="false" :onSuccess="handleExtra"
                           :data="{prefix: 'origin'}" :onProgress="handleProgress"
                           :disabled="editing && [1].includes(editing.status)"
                           :headers="{authorization: $root.authorization}">
                    <img v-if="form.origin_extra" :src="`${$root.env.CDN_URL}/${form.origin_extra}`"
                         style="width: 200px">
                    <el-button size="small" v-else :loading="loading">上传</el-button>
                </el-upload>
            </el-form-item>
        </el-form>
        <template #footer>
            <el-button type="primary" text :loading="loading" @click="reset">取消</el-button>
            <el-button type="primary" :loading="loading" @click="submit">确定</el-button>
        </template>
    </el-dialog>
    <region ref="region"></region>
</template>

<script>
import axios from 'ts-axios-new';
import {timestampToDate, update} from "../libs/utils";
import {ElMessage} from 'element-plus'
import Region from './Region.vue';
import {cleanLocalObj} from "../libs/storage";
import DateRangePicker from "../base/DateRangePicker";

export default {
    name: 'Index',
    components: {DateRangePicker, Region},
    data() {
        return {
            loading: false, data: [], category_list: [], page: 1, size: 50, author_list: [], dialog_opened: false,
            editing: null, total: 0,
            image_status_enum: {0: '测试中', 1: '已通过', 2: '不通过', null: '未完成'},
            image_module_enum: {0: '图库', 1: 'DAILY', 2: '发现页', 3: '臻选', 4: '家园', 5: '收集活动', 6: 'Bonus'},
            image_type_enum: {1: '普通', 2: 'GIF', 3: '彩绘', 4: '3d', 7: '镜像', 8: '快速涂色'},
            query: {
                status: null,
                id: null,
                module: null,
                category_id: null,
                type: null,
                magic: null,
                start: null,
                end: null,
            },
            form: {
                categories: [],
                difficulty: null,
                module: null,
                type: 0,
                video: null,
                online_date: null,
                origin_thumb: null,
                origin_extra: null,
                origin_colored: null,
                status: null,
                magic: false,
            },
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/part-time/image`).then(res => {
                    this.data = res.data.data.imageList;
                    this.total = this.data.length;
                    this.data.forEach(d => {
                        if (d.author && !this.author_list.includes(d.author)) {
                            this.author_list.push(d.author);
                        }
                    })
                }),
                axios.get('/cms/v1/category').then(res => {
                    this.category_list = res.data.data.categoryList;
                }),
            ]).then(_ => {
                this.loading = false;
            })
        },
        timestampToDate(timestamp) {
            return timestampToDate(timestamp)
        },
        openDialog(item) {
            this.dialog_opened = true;
            this.$nextTick(_ => {
                if (item) {
                    this.editing = item;
                    update(this.form, item);
                }
            })
        },
        reset() {
            this.$refs.form.resetFields();
            this.form.categories = [];
            this.editing = null;
            this.dialog_opened = this.loading = false;
        },
        submit() {
            this.$refs.form.validate(valid => {
                if (valid) {
                    this.loading = true;
                    if (this.editing) {
                        axios.put(`/cms/v1/part-time/image/${this.editing.id}`, this.form).then(res => {
                            update(this.editing, res.data.data);
                            this.reset();
                        })
                    } else {
                        axios.post(`/cms/v1/part-time/image`, this.form).then(res => {
                            this.data.unshift(res.data.data);
                            this.reset();
                        })
                    }
                }
            })
        },
        update(row) {
            this.loading = true;
            axios.put(`/cms/v1/part-time/image/${row.id}`, row).then(_ => {
                this.loading = false;
            })
        },
        handleColored(res) {
            this.loading = false;
            this.form.origin_colored = res.data.name
        },
        handleExtra(res) {
            this.loading = false;
            this.form.origin_extra = res.data.name
        },
        handleRegion(res) {
            this.loading = false;
            this.form.origin_region = res.data.name
        },
        handleThumb(res) {
            this.loading = false;
            this.form.origin_thumb = res.data.name
        },
        handleProgress() {
            this.loading = true;
        },
        handleBeforeUpload(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = e => {
                    const image = new Image();
                    image.src = e.target.result;
                    image.onload = _ => {
                        if (image.width === image.height) {
                            resolve(file);
                        } else {
                            ElMessage.error('图片不是正方形');
                            reject();
                        }
                    }
                }
                reader.readAsDataURL(file);
            })
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            const now = timestampToDate(Date.now());
            return this.data.filter(d => {
                return (!this.query.status && this.query.status !== 0 || (d.status === this.query.status && (!d.online_date || d.online_date > now) || this.query.status === 10 && d.online_date && d.online_date <= now))
                    && (!this.query.id || d.id.includes(this.query.id) || (d.nickname + '').includes(this.query.id))
                    && (!this.query.category_id || d.categories.includes(this.query.category_id))
                    && (this.query.module == null || this.query.module === '' || d.module === this.query.module)
                    && (!this.query.type || d.type === this.query.type)
                    && (!this.query.category_id || d.categories.includes(this.query.category_id))
                    && (![true, false].includes(this.query.magic) || d.magic === this.query.magic)
                    && (!this.query.author || d.author === this.query.author)
                    && (!this.query.start && !this.query.end || this.query.start <= timestampToDate(d.c_time) && this.query.end >= timestampToDate(d.c_time))
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size);
        }
    }
}
</script>

<style scoped>

</style>