import {createApp} from 'vue'
import App from './App.vue'
import Login from './Login.vue'
import axios from 'ts-axios-new'
import {createRouter, createWebHashHistory} from 'vue-router';
import ElementPlus from 'element-plus'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import 'element-plus/dist/index.css'
import 'element-plus/theme-chalk/dark/css-vars.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
import {update} from './libs/utils'
import {routes} from './routes';
import {getLocalObj} from "./libs/storage";

const router = createRouter({
    history: createWebHashHistory(),
    routes
});


const root_props = {
    // user info
    user: {
        user_id: '',
        avatar: '',
        name: '',
        email: '',
    },
    user_list: [],
    authorization: '',
};

function init() {
    const app = createApp(App);
    app.use(router);
    app.use(ElementPlus, {locale: zhCn});
    const vm = app.mount('#app');
    update(vm, root_props);
    for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
        app.component(key, component)
    }
}

function login() {
    const app = createApp(Login);
    app.use(ElementPlus, {locale: zhCn});
    app.mount('#login');
    document.getElementById('login').style.height = '100%'
}

window.onload = function () {
    const authorization = getLocalObj('authorization', null);
    axios.get(`/cms/v1/me`, {headers: {authorization}}).then(_ => {
        axios.interceptors.request.use(function (config) {
            config.headers['Authorization'] = authorization;
            return config
        }, function (error) {
            return Promise.reject(error);
        })
        root_props.authorization = authorization;
        init();
    }, _ => {
        login();
    })
};
