<template>
    <div>
        <el-date-picker v-model="form.date" :clearable="false" :disabled="loading"
                        value-format="YYYY-MM-DD"></el-date-picker>
        <el-select v-model="form.country" :disabled="loading" placeholder="全部国家" clearable>
            <el-option v-for="c in $root.country_list" :value="c.code"
                       :label="c.en_name + ' (' + c.code + ')'"></el-option>
        </el-select>
        <el-button type="primary" :disabled="loading" @click="init">查询</el-button>
    </div>
    <el-table :data="dataPage" v-loading="loading" style="margin-top: 20px" @sort-change="sortChange">
        <el-table-column label="假ID" width="100" prop="nickname"></el-table-column>
        <el-table-column label="预览图" width="120">
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`${$root.env.CDN_URL}/${scope.row.thumbnail}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="完成图" width="120">
            <template #default="scope">
                <el-image style="width: 100px" lazy
                          :src="`${$root.env.CDN_URL}/${scope.row.origin_colored}`"></el-image>
            </template>
        </el-table-column>
        <el-table-column label="作者" width="80" prop="author"></el-table-column>
        <el-table-column label="激励" width="80" prop="level">
            <template #default="scope">
                {{ scope.row.video ? '是' : '否' }}
            </template>
        </el-table-column>
        <el-table-column label="上线时间" width="140" prop="online_date" sortable></el-table-column>
        <el-table-column label="色块" width="100" prop="cells"></el-table-column>
        <el-table-column label="展示" prop="impression" width="100"></el-table-column>
        <el-table-column label="进入" prop="enter" width="100" sortable></el-table-column>
        <el-table-column label="完成" prop="finish" width="100" sortable></el-table-column>
        <el-table-column label="下载" prop="download" width="100" sortable></el-table-column>
        <el-table-column label="分享" prop="share" width="100" sortable></el-table-column>
        <el-table-column label="bomb使用" prop="bomb_rate_3" width="100"></el-table-column>
        <el-table-column label="bucket使用" prop="bucket_rate_3" width="100"></el-table-column>
        <el-table-column label="magic使用" prop="magic_rate_3" width="100"></el-table-column>
    </el-table>
    <div style="margin-top: 20px;text-align: center">
        <el-pagination v-model:current-page="page" :page-size="size" layout="prev, pager, next"
                       :total="dataFilter.length"></el-pagination>
    </div>
</template>

<script>
import {timestampToDate} from "../libs/utils";
import axios from "ts-axios-new";

export default {
    name: "Metric",
    data() {
        const now = Date.now();
        return {
            loading: false, data: [], page: 1, size: 20, image_list: [], author_list: [],
            form: {
                date: timestampToDate(now - 24 * 3600 * 1000),
                country: '',
            }
        }
    },
    methods: {
        init() {
            this.loading = true;
            axios.all([
                axios.get(`/cms/v1/performance`, {params: this.form}).then(res => {
                    this.data = res.data.data.metricList.data;
                }),
                axios.get(`/cms/v1/image`).then(res => {
                    this.image_list = res.data.data.imageList;
                    this.image_list.forEach(i => {
                        if (i.author && !this.author_list.includes(i.author)) {
                            this.author_list.push(i.author);
                        }
                    })
                }),
            ]).then(_ => {
                this.data.forEach(d => {
                    this.image_list.forEach(m => {
                        if (d.image_id === m.id) {
                            d.type = m.type;
                            d.categories = m.categories;
                            d.online_date = m.online_date;
                            d.cells = m.cells;
                            d.author = m.author;
                            d.video = m.video;
                            d.thumbnail = m.thumbnail;
                            d.origin_colored = m.origin_colored;
                            d.nickname = m.nickname;
                        }
                    });
                });
                this.loading = false;
            })
        },
        sortChange({column, prop, order}) {
            if (prop) {
                this.data.sort(function (a, b) {
                    return order === 'descending' ? (b[prop] < a[prop] ? -1 : 1) : (a[prop] < b[prop] ? -1 : 1);
                })
            } else {
                this.data.sort(function (a, b) {
                    return b.enter_rate_3 - a.enter_rate_3;
                })
            }
        },
    },
    mounted() {
        this.init();
    },
    computed: {
        dataFilter() {
            return this.data.filter(d => {
                let valid = d.impression > 100;
                valid &= !this.form.id || (d.image_id + '').includes(this.form.id);
                valid &= !this.form.author || d.author === this.form.author;
                return valid;
            })
        },
        dataPage() {
            return this.dataFilter.slice((this.page - 1) * this.size, this.page * this.size)
        }
    }
}
</script>

<style scoped>

</style>